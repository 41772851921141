import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home.vue'),
    meta: {
      layout: 'AppLayoutHome'
    }
  },
  {
    path: '/pos-data',
    name: 'DataGraphPosition',
    component: () => import('@/views/DataGraphPosition.vue'),
    meta: {
      layout: 'AppLayoutGraph'
    }
  },
  {
    path: '/mic-data',
    name: 'DataGraphMic',
    component: () => import('@/views/DataGraphMic.vue'),
    meta: {
      layout: 'AppLayoutGraph'
    }
  },
  {
    path: '/bat-data',
    name: 'DataGraphBattery',
    component: () => import('@/views/DataGraphBattery.vue'),
    meta: {
      layout: 'AppLayoutGraph'
    }
  },

  {
    path: '/sensors-data',
    name: 'DataGraphSensors',
    component: () => import('@/views/DataGraphSensors.vue'),
    meta: {
      layout: 'AppLayoutGraph'
    }
  },

  {
    path: '/bioz-data',
    name: 'DataGraphBioz',
    component: () => import('@/views/DataGraphBioz.vue'),
    meta: {
      layout: 'AppLayoutGraph'
    }
  },
  {
    path: '/liquid-data',
    name: 'DataGraphLiquid',
    component: () => import('@/views/DataGraphLiquid.vue'),
    meta: {
      layout: 'AppLayoutGraph'
    }
  },

  {
    path: '/test',
    name: 'Test',
    component: () => import('@/views/Home.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router