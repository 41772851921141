import Vue from "vue"
import Vuex from "vuex";
 
Vue.use(Vuex);
 
export default new Vuex.Store({
 state: {
  bleConnecting: false,
  bleConnected: false,
  bleDevice: null,

  sBatteryAvailable: false,
  sAmbientAvailable: false,
  sMotionAvailable: false,
  sSignalingAvailable: false,
  sMicAvailable: false,
  sImpedanceAvailable: false,

  batData: {
    ts: [],
    lvl: [],
    charging: false
  },

  batHandles: {
    lvl: null,
    shutdownReboot: null
  },

  ambData: {
    temp: [0],
    press: [0],
    hum: [0],
    opt: [0],
    tempExt: [0],
    pressExt: [0]
  },

  ambHandles: {
    temp: null,
    press: null,
    hum: null,
    opt: null,  //OPT300X
    pressExt: null // press and temp
  },

  motDataAcc: {
    x: [],
    y: [],
    z: [],
  },

  motDataGyr: {
    x: [],
    y: [],
    z: [],
  },

  motHandles: {
    acc: null,
    gyr: null
  },

  micData: {
    fftBins: []
  },

  micHandles: {
    micFft: null,
    micFftConf: null
  },

  impHandles: {
    complexData: null,
    complexConf: null,
    calib: null
  },

  impData: {
    fHz: [],
    real: [],
    imag: [],
    imp: [],
    mag: []
  },

  liquidClassValues: {
    impMaxWater: null,
    impMaxOrange: null,
    impMaxBanana: null,
    impMaxRum: null
  },

  signalingLedHandles: {
    ledRed: null,
    ledGreen: null,
    ledBlue: null
  },

 },

 getters: {
  getServicesState(state) {
    let sStates = {
      sAmbient: state.sAmbientAvailable,
      sMotion: state.sMotionAvailable,
      sSignaling: state.sSignalingAvailable,
      sMic: state.sMicAvailable,
      sImpedance: state.sImpedanceAvailable
    }

    return sStates
  },

  /* The slice() function creates a shallow copy of the data. 
   * Needed to remove the reference to the vuex state data
   * because data is modified externally (e.g. deg -> rad)
   */
  getLastAccData(state) {
    if(state.motDataAcc.x.length == 0) return null

    let accData = {
      x: state.motDataAcc.x.slice(-1)[0],
      y: state.motDataAcc.y.slice(-1)[0],
      z: state.motDataAcc.z.slice(-1)[0]
    }

    return accData
  },

  getLastGyrData(state) {
    if(state.motDataGyr.x.length == 0) return null

    let gyrData = {
      x: state.motDataGyr.x.slice(-1)[0],
      y: state.motDataGyr.y.slice(-1)[0],
      z: state.motDataGyr.z.slice(-1)[0]
    }

    return gyrData
  },

  getLastFftBins(state) {
    if(state.micData.fftBins.length == 0) return null

    return state.micData.fftBins.slice()
  },

  getImpMaxData(state) {
    let maximum = 0

    for(let i = 0; i < state.impData.imp.length; i++) {
      if(state.impData.imp[i] > maximum) {
        maximum = state.impData.imp[i]
      }
    }

    return maximum
  }

 },

 mutations: {

  /******************************************** BLE STATE */
  updateBleConnecting(state, bleConnecting) {
    state.bleConnecting = bleConnecting
  },
  updateBleConState(state, bleConnected) {
    state.bleConnected = bleConnected
  },
  updateBleDevice(state, bleDevice) {
    state.bleDevice = bleDevice
  },

  /******************************************** BATTERY */
  setBatteryAvailable(state, available) {
    state.sBatteryAvailable = available
  },

  updateBatteryData(state, batData) {
    state.batData.ts.push(batData.ts)
    state.batData.lvl.push(batData.lvl)
  },

  updateBatteryHandles(state, handles) {
    state.batHandles.lvl = handles.lvl
    state.batHandles.shutdownReboot = handles.shutdownReboot
  },

  updateBatteryCharging(state, charging) {
    state.batData.charging = charging
  },

  /******************************************** AMBIENT */
  setAmbientAvailable(state, available) {
    state.sAmbientAvailable = available
  },
  updateAmbHandles(state, handles) {
    state.ambHandles.temp = handles.temp
    state.ambHandles.press = handles.press
    state.ambHandles.hum = handles.hum
    state.ambHandles.opt = handles.opt
    state.ambHandles.pressExt = handles.pressExt
  },
  updateAmbTemp(state, temp) {
    if(state.ambData.temp.length >= 25) {
      state.ambData.temp.shift()
    }

    state.ambData.temp.push(temp)
  },
  updateAmbPress(state, press) {
    if(state.ambData.press.length >= 25) {
      state.ambData.press.shift()
    }

    state.ambData.press.push(press)
  },
  updateAmbHum(state, hum) {
    if(state.ambData.hum.length >= 25) {
      state.ambData.hum.shift()
    }

    state.ambData.hum.push(hum)
  },
  updateAmbOpt(state, opt) {
    if(state.ambData.opt.length >= 25) {
      state.ambData.opt.shift()
    }

    state.ambData.opt.push(opt)
  },
  updateAmbExt(state, data) {
    if(state.ambData.tempExt.length >= 25) {
      state.ambData.tempExt.shift()
    }
    if(state.ambData.pressExt.length >= 25) {
      state.ambData.pressExt.shift()
    }

    state.ambData.tempExt.push(data.temp)
    state.ambData.pressExt.push(data.press)
  },

  /******************************************** SIGNALING */
  setSignalingAvailable(state, available) {
    state.sSignalingAvailable = available
  },
  updateSignalingLedHandles(state, handles) {
    state.signalingLedHandles.ledRed = handles.ledRed
    state.signalingLedHandles.ledGreen = handles.ledGreen
    state.signalingLedHandles.ledBlue = handles.ledBlue
  },

  /******************************************** MOTION */
  setMotionAvailable(state, available) {
    state.sMotionAvailable = available
  },
  updateMotHandles(state, motHandles) {
    state.motHandles.acc = motHandles.acc
    state.motHandles.gyr = motHandles.gyr
  },
  updateMotDataAcc(state, motDataAcc) {
    if(state.motDataAcc.x.length >= 50) {
      state.motDataAcc.x.shift()
      state.motDataAcc.y.shift()
      state.motDataAcc.z.shift()
    }

    state.motDataAcc.x.push(motDataAcc.x)
    state.motDataAcc.y.push(motDataAcc.y)
    state.motDataAcc.z.push(motDataAcc.z)
  },
  updateMotDataGyr(state, motDataGyr) {
    if(state.motDataGyr.x.length >= 50) {
      state.motDataGyr.x.shift()
      state.motDataGyr.y.shift()
      state.motDataGyr.z.shift()
    }

    state.motDataGyr.x.push(motDataGyr.x)
    state.motDataGyr.y.push(motDataGyr.y)
    state.motDataGyr.z.push(motDataGyr.z)
  },

  /******************************************** MICROPHONE */
  setMicAvailable(state, available) {
    state.sMicAvailable = available
  },
  updateMicHandles(state, micHandles) {
    state.micHandles.micFft = micHandles.micFft
    state.micHandles.micFftConf = micHandles.micFftConf
  },
  updateMicDataFft(state, micDataFft) {
    for(let i = 0; i < micDataFft.length; i++) {
      state.micData.fftBins[i] = micDataFft[i]
    }
  },

  /******************************************** IMPEDANCE */
  setImpedanceAvailable(state, available) {
    state.sImpedanceAvailable = available
  },
  updateImpHandles(state, impHandles) {
    state.impHandles.complexData = impHandles.complexData
    state.impHandles.complexConf = impHandles.complexConf
    state.impHandles.calib = impHandles.calib
  },
  updateImpData(state, impData) {
    state.impData.fHz.push(impData.fHz)
    state.impData.real.push(impData.real)
    state.impData.imag.push(impData.imag)
    state.impData.imp.push(impData.imp)
    state.impData.mag.push(impData.mag)
  },
  clearImpData(state) {
    state.impData.fHz = []
    state.impData.real = []
    state.impData.imag = []
    state.impData.imp = []
    state.impData.mag = []
  },
  updateImpMaxWater(state, impMaxWater){
    state.liquidClassValues.impMaxWater = impMaxWater
  },
  updateImpMaxOrange(state, impMaxOrange){
    state.liquidClassValues.impMaxOrange = impMaxOrange
  },
  updateImpMaxBanana(state, impMaxBanana){
    state.liquidClassValues.impMaxBanana = impMaxBanana
  },
  updateImpMaxRum(state, impMaxRum){
    state.liquidClassValues.impMaxRum = impMaxRum
  }  
 },

 actions: {}

});