import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import AppLayout from '@/layouts/AppLayout'
import store from './store'

Vue.use(BootstrapVue)

Vue.component('AppLayout', AppLayout)

Vue.config.productionTip = false

new Vue({
	store,
	el: '#app',
	router,
	render: h => h(App)
}).$mount('#app')
