<template>
  <div id="app">
    <AppLayout>
      <router-view/>
    </AppLayout>
  </div>
</template>

<script>
  export default {
    name: 'Sensorboard',
    metaInfo: {
      title: 'Sensorboard',
      /* HTML meta tag to load immages over HTTPS (avoid Mixed Content warning) */
      meta: [
        { 'http-equiv': 'Content-Security-Policy', content: 'upgrade-insecure-requests' }
      ]
    }
  }


</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*color: rgb(0, 91, 127);*/
  background-color: #2c3e50;
  /*background-color: rgb(23, 156, 125);*/
  height: 100%;
}
:root {
  --tileColor: white;
  --accent: rgb(0, 147, 116);
  --appMaxWidth: 465px;
}
</style>